import { graphql } from 'gatsby';
import DocsComponent from '@rocketseat/gatsby-theme-docs-core/src/components/Docs';

export default DocsComponent;

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        sidebarSection
        title
        description
        image
        disableTableOfContents
        embeddedImages {
          title
          link
          file {
            name
            childImageSharp {
              original {
                width
                height
                src
              }
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      body
      headings {
        depth
        value
      }
    }
  }
`;