import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from "gatsby-image"

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import 'rc-tabs/assets/index.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { Icon } from "react-icons-kit"
import { imac } from 'react-icons-kit/ikons/imac'

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';
import PostNav from '@rocketseat/gatsby-theme-docs/src/components/Docs/PostNav';

import Box from "common/src/components/Box"
import Button from "common/src/components/Button"
import "./anchor-fix.css"

const Tabbed = ({ tabs }) => <Tabs
  renderTabBar={() => <ScrollableInkTabBar />}
  renderTabContent={() => <TabContent animated={false} />}
  className="update-screen-tab"
>
  {tabs.map((item, index) => (
    <TabPane
      tab={<span style={{ fontSize: "1.5em" }}>
        {item.icon}
        {item.title}
      </span>
      }
      key={index + 1}
    >
      <br />
      {item.child}
    </TabPane>
  ))}
</Tabs>

const DoubleImage = ({ img1, img2, alt1, alt2, width1, width2, mw1, mw2, onClickImg, mobileWidth1, mobileWidth2 }) => {
  return <div style={{ display: "flex", flexWrap: "wrap" }}>
    <Box style={{ textAlign: "center", maxWidth: mw1, margin: "auto" }} width={[mobileWidth1 || "100%", width1 || (img2 ? "60%" : 1)]} mb='30px' >
      <div onClick={() => onClickImg(img1.name)}>
        <Img fluid={img1.fluid} imgStyle={{ cursor: "zoom-in", border: "1px solid #008abe" }} alt={alt1 || img1.title} />
        <span style={{ textAlign: "center", opacity: 0.4 }}>{alt1 || img1.title}</span>
      </div>
    </Box>
    {img2 && <Box style={{ textAlign: "center", maxWidth: mw2, margin: "auto" }} width={[mobileWidth2 || "50%", width2 || "40%"]} mb='30px' >
      <div onClick={() => onClickImg(img2.name)}>
        <Img fluid={img2.fluid} imgStyle={{ cursor: "zoom-in", paddingLeft: 10 }} alt={alt2 || img2.title} />
        <span style={{ textAlign: "center", opacity: 0.4 }}>{alt2 || img2.title}</span>
      </div>
    </Box>}
  </div>
}

const LightBoxWrapper = ({ open, index, setOpen, setIndex, images }) => {
  if (!open) return null

  return <Lightbox
    mainSrc={images[index].src}
    nextSrc={images[(index + 1) % images.length].src}
    prevSrc={images[(index + images.length - 1) % images.length].src}
    onCloseRequest={() => setOpen(false)}
    onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
    onMoveNextRequest={() => setIndex((index + 1) % images.length)}
    imageTitle={<div style={{ display: "flex" }}>
      <span>{images[index].title}</span>
      {images[index].link &&
        <a href={images[index].link}>
          <Button
            style={{ marginLeft: 15, marginTop: 2 }}
            colors="primaryWithBg"
            variant="extendedFab"
            title="Open In Demo"
            iconPosition="left"
            icon={<Icon icon={imac} size={32} />}
            aria-label="open in demo"
          />
        </a>
      }
    </div>}
    reactModalStyle={{
      content: {
        zIndex: 2000,
      }
    }}
  />
}

const mdxComponents = {
  DoubleImage,
  Tabbed,
}

const getLargestImage = srcSet => {
  const sets = srcSet.split(",")
  if (sets.length > 0) {
    const largest = sets[sets.length - 1]
    const src = largest.split(" ")[0]
    return src.trim()
  }
}

export default function Docs({ mdx, pageContext }) {
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const { prev, next, githubEditUrl } = pageContext;
  const { sidebarSection, title, description, image, disableTableOfContents, embeddedImages } = mdx.frontmatter;
  const { headings, body } = mdx;
  const { slug } = mdx.fields;

  const embeddedImagesByKey =
    embeddedImages &&
    embeddedImages.reduce((images, image, index) => {
      if (image) {
        images[image.file.name] = {
          name: image.file.name,
          title: image.title,
          ...image.file.childImageSharp,
        }
      }
      return images
    }, {})

  const images = embeddedImages && embeddedImages.map(image => {
    return {
      name: image.file.name,
      title: image.title,
      link: image.link,
      // src: getLargestImage(image.file.childImageSharp.fluid.srcSet)
      ...image.file.childImageSharp.original
    }
  })

  const zoomImage = name => {
    const index = images.findIndex(i => i.name === name)
    if (index > -1) {
      setIndex(index)
      setOpen(true)
    }
  }

  const pageImage = images?.length > 0 && images[0]

  return (
    <>
      <SEO title={title} description={description} slug={slug} image={pageImage?.src} imageWidth={pageImage?.width} imageHeight={pageImage?.height} alt={pageImage?.title} />
      <Layout
        disableTableOfContents={disableTableOfContents}
        title={title}
        headings={headings}
        sidebarSection={sidebarSection}
      >
        <LightBoxWrapper open={open} setOpen={setOpen}
          index={index} setIndex={setIndex}
          images={images}
        />
        <MDXProvider components={mdxComponents}>
          <MDXRenderer
            embeddedImages={embeddedImagesByKey}
            zoomImage={zoomImage}
          >{body}</MDXRenderer>
        </MDXProvider>
        <PostNav prev={prev} next={next} />
      </Layout>
    </>
  );
}

Docs.propTypes = {
  mdx: PropTypes.shape({
    body: PropTypes.string,
    headings: PropTypes.array,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      disableTableOfContents: PropTypes.bool,
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    prev: PropTypes.shape({}),
    next: PropTypes.shape({}),
    githubEditUrl: PropTypes.string,
  }).isRequired,
};
